<template>
  <div class="food-item">
    <component
      :is="tag"
      :to="to"
    >
      <van-icon
        v-if="foodIcon"
        :name="$icon(foodIcon)"
        size="21"
        class="food-item__icon"
      />
      <div class="food-item__content">
        <p class="food-item__title">
          {{ title }}
        </p>
        <p class="food-item__subtitle">
          {{ subtitle }}
        </p>
      </div>
      <span
        v-if="diagnosis"
        class="food-item__phe"
      >
        {{ diagnosisValue }} {{ getNutritionUnit(diagnosis.nutritionKey) }}
        <span class="food-item__phe-unit">
          {{ getAbbreviation(diagnosis.nutritionKey) }}
        </span>
      </span>
    </component>
    <slot
      class="food-item__action"
      name="action"
    />
  </div>
</template>

<script>
import { getNutritionUnit, getAbbreviation } from '@/helper';

export default {
  props: {
    baseUnit: { type: String, default: '' },
    diagnosis: { type: Object, default: null },
    foodType: { type: String, default: '' },
    nutritions: { type: Object, required: true },
    nutritionsCalculated: { type: Object, default: null },
    title: { type: String, required: true },
    to: { type: [Object, String], default: null },
    portionAmount: { type: Number, default: 0 },
    portionSizeName: { type: String, default: '' },
    selectedPortionAmount: { type: Number, default: 0 },
    selectedUnit: { type: String, default: '' },
    baseAmount: { type: Number, default: 0 },
  },

  computed: {
    portionCount() {
      return this.selectedPortionAmount && this.portionAmount
        ? this.selectedPortionAmount / this.portionAmount
        : 1;
    },

    diagnosisValue() {
      return this.nutritions?.[this.diagnosis.nutritionKey]
        ?.toLocaleString('de-DE', { maximumFractionDigits: 1 }) || 0;
    },

    // Display an icon for meal food types.
    foodIcon() {
      return this.foodType === 'meal' ? 'meal' : false;
    },

    subtitle() {
      const items = [];

      if (this.portionSizeName
        && (!this.selectedUnit || this.selectedUnit === this.portionSizeName)
        && !['Gramm (g)', 'Milliliter (ml)'].includes(this.portionSizeName)
      ) {
        // Food with a custom portion size does not have a selectedUnit.
        // Food with a custom portion size in a meal sometimes has a matching
        // selectedUnit set.
        // A bug caused global food from search results to be quick-added in the
        // default amount with a portionSizeName, which causes the amount to get
        // multiplied by the portionAmount (100).
        items.push(`${this.portionCount} ${this.portionSizeName}`);
      } else if (this.selectedPortionAmount && this.baseUnit) {
        // Otherwise, show the food with the amount in the base unit (g or ml).
        // Meals in the search result do not have a baseUnit set; a "meal"
        // itself is the portion size and always 1, which we omit.
        items.push(`${this.selectedPortionAmount} ${this.baseUnit}`);
      } else if (this.baseAmount && this.baseUnit) {
        // For global foods without custom portion size, output the base amount.
        items.push(`${this.baseAmount} ${this.baseUnit}`);
      }

      if (this.nutritions?.calories && typeof this.nutritions.calories === 'number') {
        items.push(`${this.calculateNutritionValue(this.nutritions.calories).toLocaleString('de-DE')} kcal`);
      }

      if (this.nutritions?.protein && typeof this.nutritions.protein === 'number') {
        items.push(`${this.calculateNutritionValue(this.nutritions.protein).toLocaleString('de-DE')} g ${this.$i18n.t('protein')}`);
      }

      return items.join(' / ');
    },

    tag() {
      return this.to ? 'router-link' : 'div';
    },
  },
  methods: {
    getAbbreviation,
    getNutritionUnit,
    calculateNutritionValue(value) {
      if (this.nutritionsCalculated || !this.portionAmount) {
        return value;
      }
      return Math.round(
        value * ((this.selectedPortionAmount || this.portionAmount) / this.baseAmount),
      );
    },
  },
};
</script>

<style lang="scss">
@use '~@/styles/config' as config;

.food-item {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px #{config.$spacing};

  > :not(button) {
    display: inherit;
    flex: 1;
  }

  a {
    color: currentColor;
  }

  &:after {
    position: absolute;
    bottom: 0;
    left: #{config.$spacing};
    display: block;
    width: calc(100% - #{config.$spacing * 2});
    height: 1px;
    border-bottom: solid 1px config.$color-mid-light;
    content: '';
  }
}

.food-item__icon {
  margin-right: 0.38em;
}

.food-item__add {
  padding: 0.125rem;

  // Higher specificity needed over `.van-swipe-cell__wrapper .van-button`
  &.van-button.van-button--round {
    width: 1.625rem;
    height: 1.625rem;
  }

  .van-button__icon {
    line-height: 1;
    font-size: 1rem;
    color: #fff;
  }
}

.food-item__content {
  flex-grow: 1;
  width: 0; // Ensure no overflow. Content expands with flex-grow.
  margin-right: 20px;

  p {
    margin: 0;
  }
}

.food-item__title {
  overflow: hidden;
  margin-bottom: 0.25em;
  font-size: config.$font-size;
  text-overflow: ellipsis; // restrict width of element to get this working.
  white-space: nowrap;
  @media(min-width: 1024px) {
    font-size: config.$font-size-md;
  }
}

.food-item__subtitle {
  font-size: 0.6875rem;
  font-weight: 300;
  @media(min-width: 1024px) {
    font-size: 0.8125rem;
  }
}

.food-item__phe {
  margin-right: 1.125em;
  margin-bottom: 0.25em;
  font-size: config.$font-size;
  text-align: right;
}

.food-item__phe-unit {
  display: block;
  font-size: 0.6875rem;
  font-weight: 300;
}
</style>
