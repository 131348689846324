import {
  auth,
  db,
  FieldValue,
} from '@/firebase';

import Dayplan from './dayplan';

export default class Meal {
  static reference = db.collection('meals');

  static dayplanReference = Dayplan.reference();

  static getAll() {
    return db.collection('meals')
      .where('createdBy', '==', auth.currentUser.uid)
      .orderBy('updated', 'desc')
      .get()
      .then((snapshot) => {
        const items = [];
        snapshot.docs.forEach((doc) => {
          items.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        return items;
      });
  }

  static create(data) {
    const { foods, ...item } = data;
    return this.reference.add({
      ...item,
      foodType: 'meal',
      createdBy: auth.currentUser.uid,
      created: FieldValue.serverTimestamp(),
      updated: FieldValue.serverTimestamp(),
    }).then((docRef) => {
      const batch = db.batch();
      foods.forEach((dayplanFood) => {
        const {
          // Remove foodId for consistency with existing meals.
          foodId,
          mealType,
          mealTypes,
          ...food
        } = dayplanFood;
        const id = dayplanFood.foodId ?? dayplanFood.id;
        food.id = id;
        const foodDocRef = this.reference.doc(docRef.id).collection('mealFoods').doc(id);
        batch.set(foodDocRef, food);
      });
      batch.commit();
      return docRef;
    });
  }

  static update(data) {
    return this.reference.doc(data.mealId).set({
      ...data,
      foodType: 'meal',
      updated: FieldValue.serverTimestamp(),
    }, { merge: true });
  }

  static getMeal(mealId) {
    return this.reference.doc(mealId);
  }

  static getMealFoods(id) {
    return db.collection(`meals/${id}/mealFoods`).get();
  }

  static updateNutrition(mealId, nutr) {
    this.reference.doc(mealId).set({ nutritions: nutr }, { merge: true });
  }
}
